<template>
  <div class="login">
    <h1>{{ this.$t("auth.header_reset") }}</h1>
    <div
      class="section"
      v-if="this.result_status == '' || this.result_status == 'OK'"
    >
      <ul class="info">
        <li>
          <div class="title">{{ this.$t("auth.title_name") }}</div>
          <div class="value">{{ this.user_name }}</div>
        </li>
        <li>
          <div class="title">{{ this.$t("auth.title_email") }}</div>
          <div class="value">{{ this.user_email }}</div>
        </li>
        <li>
          <div class="title">{{ this.$t("auth.title_country") }}</div>
          <div class="value">
            {{ this.common.get_country_name_by_code3(this.user_country) }}
          </div>
        </li>
      </ul>
    </div>
    <div
      class="section text-center"
      v-if="this.result_status != '' && this.result_status != 'OK'"
    >
      <div class="result">{{ this.result_text }}</div>
    </div>
    <div
      class="section"
      v-if="this.result_status == '' || this.result_status == 'OK'"
    >
      <label for="password">{{ $t("auth.title_new_password") }}</label>
      <input type="password" v-model="this.new_password" />
      <p class="error">{{ error_password }}</p>
    </div>
    <div
      class="section"
      v-if="this.result_status == '' || this.result_status == 'OK'"
    >
      <label for="password">{{ $t("auth.title_new_password_repeat") }}</label>
      <input type="password" v-model="this.new_password_repeat" />
      <p class="error">{{ error_password_repeat }}</p>
    </div>
    <div
      class="section"
      v-if="this.result_status == '' || this.result_status == 'OK'"
    >
      <button
        class="button red"
        :class="{ inactive: !is_active_button }"
        @click="this.try_reset"
      >
        {{ $t("auth.button_reset") }}
      </button>
    </div>

    <div
      class="section"
      v-if="this.result_status != '' && this.result_status != 'OK'"
    >
      <button class="button red" @click="this.$router.push('/')">
        {{ $t("common.button_main") }}
      </button>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "Reset",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.reset"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.reset"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      new_password: "",
      new_password_repeat: "",
      is_active_button: false,

      error_password: "",
      error_password_repeat: "",

      change_token: "",
      user_id: 0,
      user_name: "",
      user_email: "",
      user_country: "KOR",

      result_status: "", // ALREADY_LOGIN, NO_TOKEN, NO_DATA, ALREADY_COMPLTE, EXPIRE, ERROR
      result_text: "",
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  watch: {
    new_password: function () {
      this.validate_password();
      this.check_button();
    },
    new_password_repeat: function () {
      this.validate_password_repeat();
      this.check_button();
    },
  },
  mounted() {
    if (typeof this.$route.params.change_token != "undefined") {
      this.change_token = this.$route.params.change_token;
    }

    this.load_token_data();
  },
  methods: {
    validate_password: function () {
      if (this.new_password.trim() == "")
        // 비밀번호를 입력해주세요.
        this.error_password = this.$t("auth.error_enter_password");
      else this.error_password = "";
    },
    validate_password_repeat: function () {
      if (this.new_password_repeat.trim() == "")
        // 비밀번호를 입력해주세요.
        this.error_password_repeat = this.$t("auth.error_enter_password");
      else this.error_password_repeat = "";

      if (this.new_password != this.new_password_repeat)
        // 비밀번호가 일치하지 않습니다.
        this.error_password_repeat = this.$t("auth.error_password_not_match");
      else this.error_password_repeat = "";
    },

    check_button: function () {
      if (
        this.new_password.trim() != "" &&
        this.new_password_repeat.trim() != "" &&
        this.new_password == this.new_password_repeat
      ) {
        this.is_active_button = true;
      } else {
        this.is_active_button = false;
      }
    },

    // 비밀번호 재설정 토큰을 이용해서 정보 가져오기
    load_token_data: function () {
      let self = this;
      self.process_queue.push(1);

      // 데이터 검증
      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/password/token",
          {
            change_token: self.change_token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          if (data.result.startsWith("ERR")) {
            self.result_status = "ERROR";
            self.result_text = self.$t("common.error_occur");
          }

          if (self.change_token == "") {
            self.result_status = "NO_TOKEN";
            self.result_text = self.$t("auth.error_no_token");
          }

          switch (data.result) {
            case "ERR_ALREADY_LOGIN":
              self.result_status = "ALREADY_LOGIN";
              self.result_text = self.$t("auth.alert_already_logged_in");
              break;
            case "ERR_NO_TOKEN_DATA":
              self.result_status = "NO_DATA";
              self.result_text = self.$t("common.no_data");
              break;
            case "ERR_ALREADY_COMPLETE":
              self.result_status = "ALREADY_COMPLTE";
              self.result_text = self.$t("auth.error_already_reset");
              break;
            case "ERR_EXPIRE":
              self.result_status = "EXPIRE";
              self.result_text = self.$t("auth.error_reset_expire");
              break;
          }

          console.log("token: " + self.change_token);

          if (typeof data.data != "undefined") {
            self.user_id = data.data.user_id;
            self.user_name = data.data.user_name;
            self.user_email = data.data.user_email;
            self.user_country = data.data.user_country;

            self.result_status = "OK";
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_token_data

    process_enter: function () {
      this.try_reset();
    },

    try_reset: function () {
      if (this.processing || !this.is_active_button) return;
      let self = this;

      this.$swal
        .fire({
          text: this.$t("mypage.confirm_save"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.reset_password();
          }
        }); //-- swal
    }, //-- try_reset

    reset_password: function () {
      if (this.processing || !this.is_active_button) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/password/reset",
          {
            change_token: self.change_token,
            user_id: self.user_id,
            new_password: self.new_password,
            new_password_repeat: self.new_password_repeat,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          self.$swal
            .fire({
              text: self.$t("auth.reset_password_ok"),
              icon: "success",
            })
            .then(() => {
              self.$router.push("/auth/login");
            }); //-- swal
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        });
    }, //-- reset_password
  },
};
</script>

<style scoped>
.login > .section > ul.info {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.login > .section > ul.info > li {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0.4rem 0;
  margin: 0;
}
.login > .section > ul.info > li > .title {
  width: 100px;
  font-size: 1rem;
  font-weight: 700;
}
.login > .section > ul.info > li > .value {
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 300;
}

.login > .section > .result {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 3rem 0;
  margin: 0 0 1rem 0;
}
</style>
